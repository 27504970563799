.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #45a6b2;
  padding: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-bottom: 1px solid #45a6b2;
  font-family: "NeueHaasUnica";
}

.banner a {
  color: #fff !important;
  text-decoration: underline;
  margin: 0 4px;
}

.banner a:hover {
  opacity: 0.9;
}

.closeButton {
  position: absolute;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem;
  font-family: "NeueHaasUnica";
}
