@font-face {
  font-family: "NeueHaasUnica";
  src: url("../assets/fonts/NeueHaasUnica/Neue Haas Unica W1G.otf") format("truetype");
  font-style: normal;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasUnica-bold";
  src: url("../assets/fonts/NeueHaasUnica/Neue Haas Unica W1G Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasUnica-bolditalic";
  src: url("../assets/fonts/NeueHaasUnica/Neue Haas Unica W1G Bold Italic.otf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "NeueHaasUnica-italic";
  src: url("../assets/fonts/NeueHaasUnica/Neue Haas Unica W1G Italic.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "NeueHaasUnica-lightitalic";
  src: url("../assets/fonts/NeueHaasUnica/Neue Haas Unica W1G Light It.otf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "NeueHaasUnica-light";
  src: url("../assets/fonts/NeueHaasUnica/Neue Haas Unica W1G Light.otf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

html,
body {
  background-color: #1f2951ff;
  font-family: "NeueHaasUnica";
  color: #ffffff;
  height: 100%;
  width: 100%;
  line-height: 1.5;
  /* overflow-x: hidden; */
  margin: 0;
}

h2,
h3,
.ant-radio-wrapper {
  color: #fff;
}

.button {
  width: 148px;
  height: 62px;
  padding: 10px 15px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

.button-large {
  width: 188px;
  height: 62px;
  padding: 10px 15px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

button.button:disabled,
button.button:disabled:hover {
  background-color: #f1f1f1;
  color: #999;
  border: 0;
}

.button:last-child {
  margin: 0;
}

.button:hover,
.button:focus,
.button:active {
  color: inherit;
}

.button.primary {
  background-color: #004f9fff;
}

.button.tertiary,
.button.primary:hover,
.button.primary:active {
  background-color: #1f2951ff;
  border: 2px solid #004f9fff;
  color: #004f9fff;
}

.button.secondary {
  background-color: #0e4475;
}

.button.secondary:hover,
.button.secondary:active {
  background-color: #1f2951ff;
  border: 2px solid #fff;
  color: #1363ac;
}

.button.small {
  /* width: 100px; */
  padding: 5px 10px;
  height: 42px;
}

.button.success {
  background-color: #00d1b2;
}

.button.danger {
  background-color: #f14668;
}

.button.icon {
  width: auto;
  border-radius: 1px;
  width: 40pt;
  height: 40pt;
  cursor: pointer;
}

.button.not-allowed {
  cursor: not-allowed;
}

.button.icon svg {
  margin: 0;
}

.button svg {
  margin-right: 6px;
  font-size: 16px;
}

.page-container {
  padding: 16px 50px;
}

.hero {
  margin-bottom: 30px;
}

.title {
  height: 28pt;
  font-weight: 600;
  line-height: 1.125;
}

ul {
  padding: 0;
}

a {
  color: white !important;
  cursor: pointer;
  text-decoration: none;
  border-color: #fff;
}

.input {
  padding-left: 2em !important;
  padding-right: 2em !important;
  font-size: 20px;
}

.pagination,
.pagination-list {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  justify-content: space-between;
}

.pagination li a {
  color: #004f9f !important;
}

.pagination li.selected a {
  color: #fff !important;
}

.pagination-active,
.pagination-active:hover {
  color: #fff;
  padding: 2px;
}

.blue-link {
  color: blue !important;
  text-decoration: underline !important;
}

.bold-text {
  font-weight: 900;
}

li::marker {
  content: "";
}

.has-text-white {
  color: white !important;
}

.react-datepicker__input-container input::placeholder {
  opacity: 1;
  color: white;
}

.react-datepicker__input-container input {
  box-sizing: border-box;
}

.react-datepicker-wrapper {
  width: 100%;
  box-sizing: border-box;
}
